:root {
  /* --main-color: #fb585b; */
  --main-color: rgba(207, 2, 76, 1);
  --max-width: 800px;
}

.App {
  /* max-width: 800px;
  padding: 15px; */
  margin: 0 auto;
}

.logo {
  width: 80px;
  border-radius: 50%;
}

/* .container {
  display: grid;
  grid-template-columns: 2fr 1fr;
} */

.card {
  background-color: white;
  padding: 20px 30px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid lightgray;
}

.card-mini {
  background-color: white;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 20px;
}

.bullets {
  padding: 30px 0;
}

/* h1 {
  font-size: 32px;
} */


.text_block {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

p,
li,
button,
input {
  font-size: 18px;
  font-family: "Goth", sans-serif;
}

.btn {
  padding: 20px 50px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  text-align: center;
}

.btn-main {
  background-color: var(--main-color);
  color: white;
  border: none;
}

.btn-block {
  display: block;
}

.btn-second {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background-color: white;
}

.btn-add {
  border: 1px dashed var(--main-color);
  background-color: white;
  color: var(--main-color);
}

.btn-file {
  display: block;
  text-align: center;
  padding: 20px 0;
  background-color: var(--main-color);
  color: white;
}

.btn-next {
  display: block;
  background-color: #3202b7;
  width: 100%;
  color: white;
  border: none;
  font-size: 16px;
}

.image_container {
  margin-bottom: 30px;
}

.container_heading {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.photos_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.photo_block {
  padding: 10px;
  border: 1px solid lightgray;
}

/* .buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 40px 0;
} */





.img {
  max-width: 400px;
}

.main_image {
  width: 100%;
  border-radius: 30px;
  margin-top: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.main_block {
  display: flex;
  align-items: center;
}

li {
  margin-bottom: 10px;
}

input {
  padding: 20px 20px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 100%;
  /* max-width: 425px; */
  font-size: 16px;
  box-sizing: border-box;
}

.input {
  padding: 20px 20px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  /* max-width: 425px; */
  box-sizing: border-box;
  font-size: 16px;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Goth", sans-serif;
  font-size: 16px;
}

.emoji {
  font-size: 48px;
}

.custom-file-input {
  display: none;
}

.custom-file-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.input_block {
  position: relative;
}

.check {
  position: absolute;
  right: 15px;
  top: 15px;
}

.photo_count {
  display: flex;
  align-items: center;
}

.cur {
  background-color: var(--main-color);
}

.cur_block {
  border: none;
  display: flex;
  justify-content: space-around;
  font-size: 10px;
  font-weight: 700;
  color: gray;
  padding-top: 10px;
}



.empty {
  background-color: lightgray;
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
}

.info {
  text-align: center;
}

.suggestions {
  background-color: white;
  padding: 10px;
}

.suggestions_item {
  color: var(--main-color);
  padding: 10px;
}

.error {
  color: var(--main-color);
  margin-top: 10px;
}

.title {
  text-align: center;
  font-size: 10px;
  margin-top: 10px;
}

.bullet_container {
  text-align: center;
  margin-bottom: 30px;
}

.bullet_title {
  font-size: 32px;
  color: var(--main-color);
  font-weight: 900;
}

.bold {
  font-weight: 700;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}



/* img {
  touch-action: none;
  user-select: none;
} */

.empty_container {
  height: 240px;
}

.example {
  width: 100%;
}

.gray {
  color: gray;
}

.text_small {
  font-size: 14px;
}

input[type="checkbox" i] {
  width: auto;
}

.buttons {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

@media screen and (min-width: 426px) {
  .container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 30px;
    font-size: xx-large;
    margin-top: 50px;
  }
  .bullets {
    display: flex;
    justify-content: space-around;
    padding: 50px 0;
  }
  .case_container {
    display: none;
  }
  .bullet_title {
    font-size: 48px;
  }

  /* h1 {
    font-size: 64px;
  } */
  p,
  li {
    font-size: 22px;
  }
}
