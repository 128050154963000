.link {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--main-color);
  text-decoration: underline;
}

.link-btn {
  text-decoration: none;
}

.link:hover {
  opacity: 0.8;
}
