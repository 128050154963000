html {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
  
}

body {
  margin: 0;
  font-family: "Goth", sans-serif;
  /* font-family: Goth, serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%); */
  /* min-height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "UniSansHeavyCaps";
  src: url(./assets/UniSansHeavyCaps.ttf) format("truetype");
}
@font-face {
  font-family: "BebasNeue";
  src: url(./assets/BebasNeue.ttf) format("truetype");
}
@font-face {
  font-family: "CaviarDreams";
  src: url(./assets/CaviarDreams.ttf) format("truetype");
}
@font-face {
  font-family: "CenturyGothic";
  src: url(./assets/CenturyGothic.ttf) format("truetype");
}
@font-face {
  font-family: "Pacifico";
  src: url(./assets/Pacifico.ttf) format("truetype");
}
@font-face {
  font-family: "SavoyeLet";
  src: url(./assets/SavoyeLet.ttf) format("truetype");
}

@font-face {
  font-family: 'Goth';
  src: local('Goth'), url(./assets/fonts/gogh/Gogh-Regular.ttf) format('truetype');
}
