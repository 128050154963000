h1 {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700;
}

p {
    font-size: 16px;
}

@media screen and (min-width: 576px) {
  h1 {
    font-size: 64px;
  }
  p,
  li {
    font-size: 22px;
  }
}
