.wrapper {
  padding: 15px 7px;
  margin: 0 auto;
}

.wrapper-wide {
  max-width: 1200px;  
}

.wrapper-medium {
  /* width: 800px; */
  max-width: 800px;
  margin: 0 auto;
}
