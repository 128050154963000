.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 200px;
}

.spreads {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 600px;
  width: 100%;
  transform: translateX(-50%);
  height: 200px;
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
}

.buttons_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.btn_crop {
  /* margin-left: 20px; */
  /* padding: 5px 20px; */
  /* width: auto; */
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  
}

.btn_crop-submit {
  background-color: var(--main-color);
  color: white;
  border: 1px solid var(--main-color);
}

.btn_crop-cancel {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 1);
}

.info_block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px;
}

.cover_container {
  display: flex;
  gap: 20px;
}

input[type="color" i] {
  appearance: auto;
  inline-size: 50px;
  block-size: 27px;
  cursor: default;
  box-sizing: border-box;
  background-color: buttonface;
  color: buttontext;
  border-width: 1px;
  border-style: solid;
  border-color: buttonborder;
  border-image: initial;
  padding: 1px 2px;
}

.btn_blocks {
  display: flex;
  justify-content: space-around;
  color: lightgray;
}

.crop_block {
  color: lightgray;
  text-align: center;
  margin-bottom: 5px;
}

.btn_link:hover {
  color: var(--main-color);
  cursor: pointer;
}

.order_status {
  margin-bottom: 30px;
}

.order_status > span {
  margin-right: 20px;
}

.active {
  font-weight: 700;
}

.tag {
  padding: 3px 6px;
  margin-right: 10px;
  border-radius: 3px;
  font-size: 12px;
}

.tag-gray {
  background-color: rgb(235, 235, 235);
}

.tag-green {
  background-color: #4caf50;
  color: white;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.format {
  margin-right: 10px;
}

.select {
  margin: 0 auto;
  display: flex;
  padding: 5px 20px;
  -webkit-appearance: none;
  background-color: white;
  color: black;
  outline: none;
}

.admin_buttons_block > button {
  margin-right: 20px;
  cursor: pointer;
}

.admin_links_block {
  margin-top: 20px;
}

.admin_links_block > span {
  margin-right: 20px;
}

table {
  /* width: 300px; */
}

tr {
  height: 30px;
}

.bold {
  font-weight: 700;
}

.small {
  font-size: 14px;
}

.drawer_block {
  padding: 20px;
}

.date {
  color: lightgray;
  font-size: 9px;
}

.msg {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
}

.msg_user {
  background-color: antiquewhite;
}

.chat_input {
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
}

.order_date {
  color: lightgrey;
  font-size: 12px;
  margin-bottom: 5px;
}

.info_tag {
  margin-right: 10px;
}

.blocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}