/* input[type="range"] {
    -webkit-appearance: none;
     appearance: none;
     background: transparent;
     cursor: pointer;
     border: none;
 }

 input[type="range"]::-webkit-slider-runnable-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;  
 }

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    background-color: var(--main-color);
    height: 1rem;
    width: 1rem;    
 } */
 input[type="range"] {
    accent-color: var(--main-color);
  }
 

.crop_items_block {
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(5, 1fr);
  height: 60px;
  gap: 20px;
}

.crop_item_block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(244, 244, 244, 1);
  border: 1px solid rgba(244, 244, 244, 1);;
  color: rgba(40, 40, 40, 1);
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.crop_item_block-active {
  border-color: var(--main-color);
}

